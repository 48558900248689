
import Vue from 'vue';

export default Vue.extend({
  computed: {
    isReadyForRedirect(): boolean {
      return this.$store.getters.isAuth() && this.$store.getters.isAppReady() && !this.$store.getters.isSigning();
    },
  },
  watch: {
    isReadyForRedirect: {
      immediate: true,
      handler(to: boolean) {
        if (to) {
          const from = this.$route.query.from as string;
          if (from) {
            this.$router.replace(from);
          }
        }
      },
    },
  },
});
